import { BaseClass } from './base'

export class OrderClass extends BaseClass {
  modelRef = {
    tableId: '',
    mobile: '',
    name: '',
    sex: 1,
    comboId: '',
    peopleCount: '',
    comboPrice: '',
    bookTime: undefined,
    remark: '',
    sendSms: true
  }

  rulesRefArrive = {
    peopleCount: [{
      required: true,
      message: '请选择人数'
    }],
    comboPrice: [{
      required: true,
      message: '请选择餐标'
    }]
  }

  rulesRefBase = {
    mobile: [{
      required: true,
      message: '请输入手机号'
    }],
    name: [{
      required: true,
      message: '请输入姓名'
    }],
    peopleCount: [{
      required: true,
      message: '请选择人数'
    }],
    comboPrice: [{
      required: true,
      message: '请选择餐标'
    }]
  }

  rulesRef = {
    ...this.rulesRefBase,
    bookTime: [{
      required: true,
      message: '请选择预定时间'
    }]
  }

  gets (params) {
    return this.get(this.order, params)
  }

  personalGets (params) {
    return this.get(this.order + '/PersonalGets', { ...params })
  }

  detail (id) {
    return this.get(this.order + `/${id}`)
  }

  scanDetail (tableId) {
    return this.get(this.order + `/GetScanOrder/${tableId}`)
  }

  create (data) {
    return this.post(this.order, data)
  }

  book (data) {
    return this.post(this.order + '/UserBook', data)
  }

  scan (data) {
    return this.post(this.order + '/ScanOrder', data)
  }

  scan2 (data) {
    return this.post(this.order + '/TakeoutOrder', data)
  }

  update (data) {
    return this.put(this.order, data)
  }

  notice (id) {
    return this.get(this.order + `/BookSmsNotice/${id}`)
  }

  orderType (id, type) {
    return this.get(this.order + `/${id}/${type}`)
  }

  orderChannel (id, type) {
    return this.get(this.order + `/ChangePayChannel/${id}/${type}`)
  }

  preOrder (id) {
    return this.get(this.order + '/GetPreOrders', { id })
  }

  comboPrint (id) {
    return this.get(this.order + `/ComboPrint/${id}`)
  }

  preAccountPrint (id) {
    return this.get(this.order + `/PreSettlementPrint/${id}`)
  }

  // 结算单打印
  settlementPrint (id) {
    return this.get(this.order + `/SettlementPrint/${id}`)
  }

  // 结算单打印2
  settlementPrint2 (id) {
    return this.get(this.order + `/SettlementPrint2/${id}`)
  }

  copyOrder (id) {
    return this.get(this.order + `/CopyOrder/${id}`)
  }

  orderMoney (params) {
    return this.get(this.order + '/OrderMoneyReport', params)
  }

  exportOrderMoney (params) {
    return this.export(this.order + '/ExportOrderMoneyReport', 'GET', params)
  }

  waiterOrder (params) {
    return this.get(this.order + '/WaiterReport', params)
  }

  handlerOrder (params) {
    return this.get(this.order + '/HandlerReport', params)
  }

  setWaiter (orderId, userId) {
    return this.get(this.order + `/SetWaiter/${orderId}/${userId}`)
  }

  exportWaiterReport (params) {
    return this.export(this.order + '/ExportWaiterReport', 'GET', params)
  }

  exportHandlerReport (params) {
    return this.export(this.order + '/ExportHandlerReport', 'GET', params)
  }

  orderItems (data) {
    return this.post(this.order + '/ChangeOrderItems', data)
  }

  moling (id, molingMoney) {
    return this.get(this.order + `/OrderMoling/${id}/${molingMoney}`)
  }

  zhekou (id, discount) {
    return this.get(this.order + `/OrderDiscount/${id}/${discount}`)
  }

  youhuiquan (id, couponsId, count) {
    return this.get(this.order + `/OrderCoupons/${id}/${couponsId}/${count}`)
  }

  changeTable (orderId, tableId) {
    return this.get(this.order + `/ChangeTable/${orderId}/${tableId}`)
  }

  editTime (data) {
    return this.post(this.order + '/ChangeBookTime', data)
  }

  editRemark (data) {
    return this.post(this.order + '/ChangeRemark', data)
  }

  splitOrder (data) {
    return this.post(this.order + '/OrderSpit', data)
  }

  changeCustomer (orderId, customerMobile) {
    return this.post(this.order + '/ChangeCustomer', { orderId, customerMobile })
  }

  changeCustomer2 (orderId, customerMobile) {
    return this.post(this.order + '/ChangeOrderCustomerInfo', { orderId, customerMobile })
  }

  connectOrder (mainOrderId, orderIds) {
    return this.post(this.order + '/ConnectOrder', { mainOrderId, orderIds })
  }

  cancelConnectOrder (orderId) {
    return this.get(this.order + `/CancelConnectOrder/${orderId}`)
  }
}

export class OrderItemClass extends BaseClass {
  modelRef = {
    orderId: '',
    dishId: '',
    count: 1,
    state: 0,
    tableId: '',
    dishName: '',
    operatorId: '',
    remark: '',
    price: 0,
    timing: 0
  }

  rulesRef = {
    dishId: [{
      required: true,
      message: '请选择菜品'
    }]
  }

  add (data) {
    return this.post(this.orderItem, data)
  }

  del (id) {
    return this.delete(this.orderItem + `/${id}`)
  }

  math (id, type, count) {
    return this.get(this.orderItem + `/${id}/${type}`, { count })
  }

  move (id, newOrderId) {
    return this.get(this.orderItem + '/MoveOrderItem', { id, newOrderId })
  }

  freeDishReport (params) {
    return this.get(this.orderItem + '/FreeDishReport', params)
  }

  exportFreeDishReport (params) {
    return this.export(this.orderItem + '/ExportFreeDishReport', 'GET', params)
  }
}

export class UnionPayClass extends BaseClass {
  boxPay (data) {
    return this.post(this.unionPay + '/BoxPay', data, 'json', { timeout: 0 })
  }

  boxPayRecharge (data) {
    return this.post(this.unionPay + '/BoxPayRecharge', data, 'json', { timeout: 0 })
  }
}

export class BookRecordClass extends BaseClass {
  gets (params) {
    return this.get(this.bookRecord, params)
  }
}

export class BlackMobileClass extends BaseClass {
  gets (params) {
    return this.get(this.blackMobile, params)
  }

  create (data) {
    return this.post(this.blackMobile, data)
  }

  update (data) {
    return this.put(this.blackMobile, data)
  }

  del (id) {
    return this.delete(this.blackMobile + `/${id}`)
  }
}
